<template>
<dash-page-new
    :title="$t('All')"
    :subtitle="$t('Experts')"
    :root="$t('Dashboard')"
    icon="mdi-account-tie"

>
  <div class="my-6 d-flex justify-center">
    <ws-button-group :items="navigationSelect"></ws-button-group>
  </div>

  <div v-for="(item,i) in items" :key="i">
    <div v-if="!selectedPartner || item.uuid === selectedPartner" class="d-flex mb-6" style="position: relative">
      <img
          :src="item.img"
          alt="WeStudy Login"
          height="200"
          width="250"
          class="mr-6 wsRoundedHalf"
          style="mix-blend-mode: darken; object-fit: cover"
      />
      <v-sheet
          @click="viewPartner(item.uuid)"
          :color="wsLIGHTCARD" width="100%" class="wsRoundedHalf pa-6">
        <div class="d-flex justify-space-between">


          <h3>{{  item.name  }} </h3>
          <div class="d-flex">
            <v-chip :color="wsACCENT" outlined dark>
              <h4>{{ item.is_westudy ? 'WeStudy' : $t('Partner')  }}</h4>
            </v-chip>
            <v-chip :color="wsACCENT" class="ml-2" dark>
              <h4>{{ item.clients }} відгуків</h4>
            </v-chip>
          </div>


        </div>

        <h5 :style="`color : ${wsDARKLIGHT}`">Розробка сайту - Розробка сайту - ще 3 послуги</h5>
        <h4 class="font-weight-regular mt-2">{{ item.short_description }}</h4>

        <ws-button label="partners.application.send" class="mr-2 mt-4" />
        <!--      <ws-button label="MoreDetails" outlined class="mr-2 mt-4" />-->



        <v-expand-transition>
          <div v-if="selectedPartner === item.uuid" class="pt-8">
            <v-divider />
            <h3 class="mt-8">Про партнера</h3>
            <h4 class="font-weight-regular mb-4">Не можете визначитись, яку CRM-систему обрати?
              Консультації, які призначені для легкого старту, щоб ми могли познайомитись та легко почати роботу.
            </h4>
            <h4 class="font-weight-regular mb-4">Не можете визначитись, яку CRM-систему обрати?
              Консультації, які призначені для легкого старту, щоб ми могли познайомитись та легко почати роботу.
            </h4>
            <h4 class="font-weight-regular mb-4">Не можете визначитись, яку CRM-систему обрати?
              Консультації, які призначені для легкого старту, щоб ми могли познайомитись та легко почати роботу.
            </h4>
          </div>
        </v-expand-transition>


      </v-sheet>
    </div>
  </div>



</dash-page-new>
</template>

<script>
export default {
  name: "BusinessPartners",
  data() {
    return {
      selectedPartner: null,
      items: [
        { name : "Третяк Тетяна",
          uuid : '123',
          short_description : "Професійний дизайнер в штаті підрозділу роботи з клієнтами відділу дизайна команди WeStudy",
          img : 'https://media.istockphoto.com/id/938231330/uk/фото/жінки-дизайнери-студенти-які-працюють-разом-на-ноутбуці-в-університетському-класі-освіта.jpg?s=2048x2048&w=is&k=20&c=Sdv-dGW7fG2wTf7sSIkU2UNFT-qmM_cwIF9IDnZRVhI=',
          is_westudy : true,
          clients : 6
        },
        { name : "Шама Євгенія",
          uuid : '321',
          short_description : "Професійний дизайнер 2 в штаті підрозділу роботи з клієнтами відділу дизайна команди WeStudy",
          img : 'https://images.unsplash.com/photo-1573164713988-8665fc963095?q=80&w=3269&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
          is_westudy : true,
          clients : 8
        },
        { name : "ProCRM Academy",
          uuid : '444',
          short_description : "Запуск школи під ключ, ЦРМ налаштування маркетинг, прсування",
          img : 'https://res2.weblium.site/res/64f32da1ad4fa60016d40d23/653bb8f5dd3736001764f29c_optimized.webp',
          is_westudy : false,
          clients : 8
        }
      ]
    }
  },
  computed : {
    navigationSelect() {
      return [
        { text : this.$t('All') , value : null },
        { text : this.$t('Design') , value : 'design' },
        { text : this.$t('Упаковка курсів') , value : 'Упаковка курсів' },
        { text : this.$t('Курс під ключ') , value : 'Курс під ключ' },
        { text : this.$t('CRM') , value : 'CRM' },
        { text : this.$t('Маркетинг') , value : 'Маркетинг' },
        { text : this.$t('Продажі') , value : 'Продажі' },
        { text : this.$t('Сайт') , value : 'Сайт' },
        { text : this.$t('Інше') , value : 'other' }



      ]
    }
  },
  methods : {
    viewPartner(uuid) {
      if (!this.selectedPartner) {
        this.selectedPartner = uuid
      } else {
        this.selectedPartner = null
      }
    }
  }
}
</script>

<style scoped>

</style>